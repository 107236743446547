import React from "react"
import "./styles/Button.scss"

export const Button = ({ title, onPress }) => {
  return (
    <button id="button-component" onClick={onPress}>
      {title}
    </button>
  )
}
