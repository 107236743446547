import React from "react"
import Layout from "../components/layout"
import {
  HomeSection,
  MarkTwainQuote,
  SocialSection,
  AboutSection,
  PhilosophySection
} from "../components/sections/index"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <HomeSection />
    <MarkTwainQuote />
    <AboutSection />
    <PhilosophySection />
    <SocialSection />
  </Layout>
)

export default IndexPage