import React from "react"
import "./styles/Quote.scss"

export const Quote = ({ quote, author, style }) => {
  return (
    <div id="Quote" className={style}>
      <p>{quote}</p>
      <p>- {author}</p>
    </div>
  )
}
