import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import "./styles/About.scss"

const BackgroundSection = props => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "about.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage className={props.class} fluid={imageData}>
          {props.children}
        </BackgroundImage>
      )
    }}
  />
)

export const AboutSection = () => {
  return (
    <BackgroundSection class="section about">
      <div className="description_aboutme background_blur" id="aboutme">
        <h3 className="about_title_top about_title_style">about</h3>
        <p>
          Over <b>30 years</b> of <b>work</b>, <b>life</b> and{" "}
          <b>experiences</b> built in the most well-known financial communities{" "}
          <b>all around the world</b>.
        </p>
        <div className="words">
          <div className="grid-container">
            {/* <div className="space"></div> */}
            <p className="creative grid-cell">Creative</p>
            <p className="passionate grid-cell">Passionate</p>
            <p className="extrovert grid-cell">Extrovert</p>
            <p className="analytic grid-cell">Analytic</p>
          </div>
        </div>
        <h3 className="about_title_bottom about_title_style">about</h3>
      </div>
    </BackgroundSection>
  )
}
