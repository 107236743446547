import React from "react"
import { Quote } from "../Quote"
import "./styles/MarkTwainQuote.scss"

export const MarkTwainQuote = () => {
  return (
    <div className="section container MarkTwainQuote" id="marktwainquote">
      <Quote
        quote="Every smile makes you younger every day. Life is short. Break the rules, forgive quickly, kiss slowly, love deeply, laugh uncontrollably and never regret what made you smile. I firmly believe that a smile is the most beautiful accessory that a woman can wear."
        author="Mark Twain"
        style="mtq"
      />
    </div>
  )
}
