import { graphql, StaticQuery } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import React from "react"
import "./styles/Home.scss"
import "../../styles/global.scss"
import { Button } from "../Button"
import { FiChevronsDown } from "react-icons/fi"
import scrollTo from "gatsby-plugin-smoothscroll"

const BackgroundSection = props => (
  <StaticQuery
    query={graphql`
      query {
        desktop: file(relativePath: { eq: "home.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid_withWebp
            }
          }
        }
      }
    `}
    render={data => {
      // Set ImageData.
      const imageData = data.desktop.childImageSharp.fluid
      return (
        <BackgroundImage className={props.class} fluid={imageData}>
          {props.children}
        </BackgroundImage>
      )
    }}
  />
)

export const HomeSection = () => {
  return (
    // <div className={"section home"}>
    // </div>
    <BackgroundSection class="section home">
      <div className="container">
        <h1>
          Mara <br />
          <span className="surname">Di Giorgio</span>
        </h1>
        <Button title={"About Me"} onPress={() => scrollTo("#aboutme")} />
      </div>
      <div className="down-cta">
        <FiChevronsDown
          className="child"
          onClick={() => scrollTo("#marktwainquote")}
        />
      </div>
    </BackgroundSection>
  )
}
