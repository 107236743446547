import React from "react"
import {
  FaFacebookF,
  FaInstagram,
  FaLinkedinIn,
  FaTwitter,
} from "react-icons/fa"
import "./styles/Social.scss"

const openInNewTab = url => {
  const win = window.open(url, "_blank")
  win.focus()
}

export const SocialSection = () => {
  return (
    <div className="section social" id="social">
      <h3 className="social_title">Social Links</h3>
      <div className="social-icons">
        <FaTwitter
          className="social-icon FaTwitter"
          onClick={() => openInNewTab("https://twitter.com/digiomar?lang=en")}
        />
        <FaFacebookF
          className="social-icon FaFacebookF"
          onClick={() =>
            openInNewTab("https://www.facebook.com/mara.digiorgio.9")
          }
        />
        <FaLinkedinIn
          className="social-icon FaLinkedinIn"
          onClick={() =>
            openInNewTab("https://www.linkedin.com/in/maradigiorgio/")
          }
        />
        <FaInstagram
          className="social-icon FaInstagram"
          onClick={() => openInNewTab("https://instagram.com/maradigiorgio")}
        />
      </div>
    </div>
  )
}
